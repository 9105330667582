export const RECEIVE_COINS = 'RECEIVE_COINS'
export const RECEIVE_COIN = 'RECEIVE_COIN'
export const RECEIVE_COIN_REAL = 'RECEIVE_COIN_REAL'
export const FETCH_COINS = 'FETCH_COINS'
export const RECEIVE_SHIPPING_COINS = 'RECEIVE_SHIPPING_COINS'

export const FETCH_CONTACT = 'FETCH_CONTACT'
export const RECEIVE_CONTACT = 'RECEIVE_CONTACT'

export const FETCH_SHIPPING = 'FETCH_SHIPPING'
export const RECEIVE_SHIPPING = 'RECEIVE_SHIPPING'